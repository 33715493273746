<template>
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">Empresa</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3">Sucursales</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-6 lg:px-8">
    <Toast />
    <div class="flex align-items-start flex-column lg:justify-content-between lg:flex-row">
      <div>
        <div class="font-medium text-3xl text-900">Administrar Sucursales</div>
        <div class="flex align-items-center text-700 flex-wrap">
          <div class="mr-5 flex align-items-center mt-3">
            <i class="pi pi-briefcase mr-2"></i>
            <span>{{ totalRegistros }} Sucursales</span>
          </div>
        </div>
      </div>
      <div class="mt-3 lg:mt-0">
        <Button label="Nuevo" v-if="'Sucursal Crear' in auth.user.permissions" class="p-button-outlined mr-2"
          icon="pi pi-plus" v-tooltip.top="'Nueva Sucursal'" @click="openNuevo"></Button>
      </div>
    </div>
    <div class="mt-4">
      <DataTable ref="dtsucursales" :value="sucursales" :loading="cargando" dataKey="id" :paginator="true" :rows="10"
        :filters="buscar" class="p-datatable-sm"
        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
        :rowsPerPageOptions="[5, 10, 25]"
        currentPageReportTemplate="Mostrando {first} de {last} de {totalRecords} Sucursales" responsiveLayout="scroll"
        :rowHover="true">
        <template #header>
          <div class="flex flex-column md:flex-row md:justify-content-end md:align-items-end">
            <span class="block mt-2 md:mt-0 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText v-model="buscar['global'].value" placeholder="Buscar..." />
            </span>
          </div>
        </template>

        <template #loading>
          Se estan buscando las Sucursales, por favor espere!
        </template>

        <template #empty> No existen sucursales! </template>

        <Column field="id" header="CÓDIGO" :sortable="true">
          {{ data.id }}
        </Column>
        <Column field="nombre" header="NOMBRE">
          {{ data.nombre }}
        </Column>
        <Column field="direccion" header="DIRECCIÓN">
          {{ data.direccion }}
        </Column>
        <Column field="telefono" header="TELÉFONO">
          {{ data.telefono }}
        </Column>
        <Column field="estado" header="ESTADO" :sortable="true">
          <template #body="{ data }">
            <span :class="'product-badge ' + claseEstado[data.estado.value]">
              {{ data.estado.label }}
            </span>
          </template>
        </Column>
        <Column field="created_at" header="FECHA DE CREACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.created_at) }}
          </template>
        </Column>
        <Column field="updated_at" header="ÚLTIMA MODIFICACIÓN">
          <template #body="{ data }">
            {{ formatDate(data.updated_at) }}
          </template>
          |
        </Column>
        <Column header="ACCIONES">
          <template #body="slotProps">
            <Button class="p-button-icon-only p-button-raised" icon="pi pi-ellipsis-v" v-tooltip.top="'Ver Acciones'"
              @click="$refs['menu' + slotProps.data.id].toggle($event)" />
            <Menu :ref="'menu' + slotProps.data.id" :popup="true" :model="acciones(slotProps.data)">
            </Menu>
          </template>
        </Column>
      </DataTable>
    </div>

    <Dialog v-model:visible="sucursalDialog" :style="{ width: '450px' }" header="Sucursal Detalle" :modal="true"
      class="p-fluid">
      <!-- <img :src="'images/product/' + product.image" :alt="product.image" v-if="product.image" width="150" class="mt-0 mx-auto mb-5 block shadow-2" /> -->
      <div class="col-12">
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-6">
            <label for="nombre"><strong>Nombre/Raz&oacute;n Social:</strong> <span class="p-invalid">*</span> </label>
            <InputText id="nombre" v-model.trim="sucursal.nombre" required="true" autofocus />
            <small class="p-invalid" v-if="errors.nombre">{{
              errors.nombre[0]
            }}</small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="nit"><strong>Nit Empresa:</strong> <span class="p-invalid">*</span> </label>
            <InputText id="nit" v-model.trim="sucursal.nit" required="true" autofocus />
            <small class="p-invalid" v-if="errors.nit">{{
              errors.nit[0]
            }}</small>
          </div>
          <div class="field col-12 md:col-12">
            <label for="direccion"><strong>Direcci&oacute;n:</strong> <span class="p-invalid">*</span></label>
            <Textarea id="direccion" v-model="sucursal.direccion" required="true" autofocus rows="2" />
            <small class="p-invalid" v-if="errors.direccion">{{
              errors.direccion[0]
            }}</small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="telefono"><strong>Tel&eacute;fono:</strong> </label>
            <InputText id="telefono" v-model="sucursal.telefono" />
            <small class="p-invalid" v-if="errors.telefono">{{
              errors.telefono[0]
            }}</small>
          </div>
          <div class="field col-12 md:col-6">
            <label for="estado"><strong>Estado:</strong></label>
            <Dropdown id="estado" v-model="sucursal.estado" :disabled="!sucursal.id" :options="estados"
              optionLabel="label" placeholder="Seleccione un estado">
              <template #value="slotProps">
                <span :class="'product-badge ' + claseEstado[slotProps.value.value]">
                  {{ slotProps.value.label }}
                </span>
              </template>
            </Dropdown>
          </div>
        </div>
      </div>
      <template #footer>
        <div class="field">
          <label class="flex md:justify-content-start"><strong>Campos Requeridos:</strong> <span class="p-invalid">*</span></label>
        </div>
        <Button label="Cancelar" icon="pi pi-times" class="p-button-secondary p-button-lg" @click="ocultarDialog"
          :disabled="!enviado ? false : true" />
        <Button label="Guardar" icon="pi pi-check" class="p-button-primary p-button-lg" @click="guardarSucursal"
          :disabled="!enviado ? false : true" :loading="enviado" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteSucursalDialog" :style="{ width: '450px' }" header="Confirmar" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="sucursal">Estas Seguro que quieres eliminar la sucursal
          <b>{{ sucursal.nombre }} ?</b>?</span>
      </div>
      <template #footer>
        <Button label="No" icon="pi pi-times" class="p-button-secondary" @click="deleteSucursalDialog = false" />
        <Button label="Si" icon="pi pi-check" class="p-button-primary" @click="deleteSucursal" />
      </template>
    </Dialog>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import SucursalService from "@/service/SucursalService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      sucursales: null,
      cargando: true,
      sucursalDialog: false,
      deleteSucursalDialog: false,
      sucursal: {},
      buscar: {},
      enviado: false,
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      errors: {},
      totalRegistros: 0,
      claseEstado: ["status-outofstock", "status-instock"],
    };
  },
  sucursalService: null,
  auth: null,
  created() {
    this.auth = useAuth();
    this.sucursalService = new SucursalService();
    this.initBuscar();
  },
  mounted() {
    this.cargar_sucursales();
  },
  methods: {
    cargar_sucursales() {
      this.sucursalService.getSucursalesAll().then((data) => {
        this.sucursales = data;
        this.cargando = false;
        this.sucursales.forEach((sucursal) => {
          sucursal.created_at = new Date(sucursal.created_at);
          sucursal.updated_at = new Date(sucursal.updated_at);
          sucursal.estado = {
            label: this.textoEstado(sucursal.estado),
            value: sucursal.estado,
          };
        });
      });
    },
    acciones(datos) {
      let permisosUsuarioAuth = this.auth.user.permissions;
      return [
        {
          label: "Editar",
          disabled: "Sucursal Editar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-pencil",
          command: () => {
            this.editSucursal(datos);
          },
        },
        {
          label: "Ver Detalle",
          disabled:
            "Sucursal Ver Detalle" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-eye",
          command: () => {
            this.verDetalle(datos);
          },
        },
        {
          label: "Eliminar",
          disabled: "Sucursal Eliminar" in permisosUsuarioAuth ? false : true,
          icon: "pi pi-trash",
          command: () => {
            this.confirmDeleteSucursal(datos);
          },
        },
      ];
    },
    openNuevo() {
      this.sucursal = {
        estado: { label: "Activo", value: 1 },
      };
      this.enviado = false;
      this.sucursalDialog = true;
    },
    ocultarDialog() {
      this.sucursalDialog = false;
      this.enviado = false;
    },
    guardarSucursal() {
      this.errors = {};
      this.enviado = true;
      if (this.sucursal.id) {
        //EDITANDO SUCURSAL
        let sucursal_enviar = {
          ...this.sucursal,
          estado: this.sucursal.estado.value,
        };
        this.sucursalService.updatedSucursal(sucursal_enviar).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
          } else {
            this.errors = {};

            let aux = { ...data.sucursal };

            aux.created_at = new Date(aux.created_at);
            aux.updated_at = new Date(aux.updated_at);
            aux.estado = {
              label: this.textoEstado(parseInt(aux.estado)),
              value: aux.estado,
            };

            this.sucursales[this.findIndexById(aux.id)] = aux;
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: data.mensaje,
              life: 3000,
            });
            this.ocultarModalSucursal();
          }
          this.enviado = false;
        });
      } else {
        this.errors = {};
        //Creando Nueva Sucursal

        let tmp = this.sucursal;

        this.sucursal = {
          ...this.sucursal,
          estado: this.sucursal.estado.value,
        };
        this.sucursalService.sendSucursalNueva(this.sucursal).then((data) => {
          if (Object.prototype.hasOwnProperty.call(data, "errors")) {
            this.errors = data.errors;
            this.sucursal = tmp;
          } else {
            this.errors = {};

            data.sucursal.created_at = new Date(data.sucursal.created_at);
            data.sucursal.updated_at = new Date(data.sucursal.updated_at);
            data.sucursal.estado = {
              label: this.textoEstado(parseInt(data.sucursal.estado)),
              value: data.sucursal.estado,
            };

            this.sucursales.push(data.sucursal);

            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: "Sucursal Creada",
              life: 10000,
            });
            this.ocultarModalSucursal();
          }
          this.enviado = false;
        });
      }
    },
    ocultarModalSucursal() {
      this.sucursalDialog = false;
      this.sucursal = {};
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    editSucursal(sucursal) {
      this.sucursal = { ...sucursal };
      this.sucursalDialog = true;
    },
    confirmDeleteSucursal(sucursal) {
      this.sucursal = { ...sucursal };
      this.deleteSucursalDialog = true;
    },
    deleteSucursal() {
      if (this.sucursal.id == 1) {
        this.$toast.add({
          severity: "error",
          summary: "Error!",
          detail: "No se puede eliminar la sucursal principal",
          life: 3000,
        });
        this.deleteSucursalDialog = false;
        this.sucursal = {};
        return;
      }
      this.sucursalService.deleteSucursal(this.sucursal).then((data) => {
        this.sucursales = this.sucursales.filter(
          (val) => val.id !== this.sucursal.id
        );
        this.deleteSucursalDialog = false;
        this.sucursal = {};
        this.$toast.add({
          severity: "success",
          summary: "Exito",
          detail: data.mensaje,
          life: 3000,
        });
      });
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.sucursales.length; i++) {
        if (this.sucursales[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    initBuscar() {
      this.buscar = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    verDetalle(sucursal) {
      this.$router.push(`/sucursal/${sucursal.id}/detalle`);
    },
    formatDate(value) {
      return value.toLocaleDateString("es-ES", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
      });
    },
  },
  watch: {
    sucursales() {
      this.totalRegistros = this.sucursales.length;
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
